.headingH2{
    font-family: Poppins;
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
}
.headingH6Regular{
    font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 28px;
}

.text-black-60{
    color:#676767
}
.text-center{
    text-align: center !important;
}
.Contact_us {
    margin-top: 15px;
    padding: 11px 36px;
    gap: 10px;
    border-radius: 41px;
    opacity: 1; /* Set to 1 if you want it visible */
    background: #003285;
}

.Contact_us_Link{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
    color: #ffffff; /* Remove quotes */
}

.chip-box{
    border: 1px solid #1DBAD0;
    padding: 4px 3px 4px 3px;
    color: #1DBAD0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.75px;
    text-align: center;
    width: max-content;
    border-radius: 22px;
}
.who_are_content{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color:#676767;
}
.expertise-card{
    display: flex;
    flex-direction: column;
    color: #ffffff;
    text-align: center;
    padding: 20px 14px 20px 14px;
    background: radial-gradient(166.51% 85.34% at 49.11% 49.08%, #E9D8FF -200%, rgba(255, 255, 255, 0) 26%, rgba(233, 216, 255, 0) 6%) 
}
.expertise-card:not(:last-child) {
    border-right: 2px solid #ffffff;
    margin-right: 10px;
}
.expertise-box{
    padding-top: 47px;
    padding-bottom: 47px;
    padding-left: 65px;
    padding-right: 65px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    border-radius: 22px;
    background: linear-gradient(180deg, #325DA4 0%, #003285 100%);
}
.section-Title-why-choose-us{
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    margin-bottom: 50px;
}
.management_team_feedback{
    padding: 48px 20px 30px 20px;
    gap: 30px;
    border-radius: 16px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    min-width: 0;
    max-width: 35%;
    flex-basis: 35%;
}


.team_feedback_content{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #676767;

}